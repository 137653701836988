const routes = {
    LOGIN: '/',
    APP_BANNERS: 'app-banners',
    HOME: 'home',
    CATEGORIES: 'categories',
    RECIPES: 'recipes',
    INGREDIENTS: 'ingredients',
}


export { routes }