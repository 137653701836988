import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://stage-api.mummly.in/",
});

if (window.location.hostname === "localhost") {
  axiosInstance.defaults.baseURL = "http://localhost:5000/";
}

const postFetcher = async ({ url, data }) => {
  const response = await axiosInstance.post(url, data, {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });
  return response.data;
};

const fetcher = async (url) => {
  const response = await axiosInstance.get(url, {
    withCredentials: true,
  });
  return response.data;
};

// patch
const patchFetcher = async ({ url, data }) => {
  try {
    const response = await axiosInstance.patch(url, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// delete
const deleteFetcher = async ({ url, data = {} }) => {
  try {
    const response = await axiosInstance.delete(url, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export { postFetcher, fetcher, axiosInstance, patchFetcher, deleteFetcher };
