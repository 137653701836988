import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import AppLayout from "./AppLayout";
import { routes } from "./constants/routes.constants";

const Home = lazy(() => import("./pages/Home/Home"));
const Categories = lazy(() => import("./pages/Categories/Categories"));
const Login = lazy(() => import("./pages/Login/Login"));
const Recipes = lazy(() => import("./pages/Recipes/Recipes"));
const Ingredients = lazy(() => import("./pages/Ingredients/Ingredients"));
const CreateRecipe = lazy(() => import("./pages/Recipes/CreateRecipe"));
const CreateCategory = lazy(() => import("./pages/Categories/CreateCategory"));
const CreateIngredient = lazy(() =>
  import("./pages/Ingredients/CreateIngredient")
);
const AppBanners = lazy(() => import("./pages/AppBanners/AppBanners"));
const CreateBanner = lazy(() => import("./pages/AppBanners/CreateBanner"));

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<AppLayout />}>
          <Route path={routes.APP_BANNERS}>
            <Route index element={<AppBanners />} />
            <Route path="create" element={<CreateBanner />} />
          </Route>
          <Route path={routes.HOME} element={<Home />} />
          <Route path={routes.CATEGORIES}>
            <Route index element={<Categories />} />
            <Route path="create" element={<CreateCategory />} />
          </Route>
          <Route path={routes.RECIPES}>
            <Route index element={<Recipes />} />
            <Route path="create" element={<CreateRecipe />} />
          </Route>
          <Route path={routes.INGREDIENTS}>
            <Route index element={<Ingredients />} />
            <Route path="create" element={<CreateIngredient />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
