import { postFetcher, fetcher, patchFetcher, deleteFetcher } from "./axios";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const useReactQuery = (key, url, config) => {
  return useQuery({
    queryKey: [key, url],
    queryFn: () => fetcher(url),
    ...config,
  });
};

const useReactPostQuery = (key, url) => {
  return useQuery({
    queryKey: [key, url],
    queryFn: () => postFetcher(url),
  });
};

// Custom hook
function usePostData() {
  return useMutation({
    mutationFn: postFetcher,
  });
}

function usePatchData() {
  return useMutation({
    mutationFn: patchFetcher,
  });
}

function useDeleteData() {
  return useMutation({
    mutationFn: deleteFetcher,
  });
}

export {
  useReactQuery,
  usePostData,
  useReactPostQuery,
  usePatchData,
  useDeleteData,
  useQueryClient,
};
