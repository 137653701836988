import { useState } from "react";
import { CiVideoOn } from "react-icons/ci";
import { AiOutlineHome } from "react-icons/ai";
import { BiCategory } from "react-icons/bi";
import { RiLogoutCircleLine } from "react-icons/ri";
import { routes } from "../../constants/routes.constants";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { usePostData } from "../../lib/react-query-hooks";
import { axiosInstance } from "../../lib/axios";

const Menus = [
  { title: "App Banners", link: routes.APP_BANNERS, Icon: AiOutlineHome },
  { title: "Home", link: routes.HOME, Icon: AiOutlineHome },
  { title: "Categories", link: routes.CATEGORIES, Icon: BiCategory, gap: true },
  { title: "Recipes", link: routes.RECIPES, Icon: CiVideoOn },
  { title: "Ingredients", link: routes.INGREDIENTS, Icon: CiVideoOn },
];

export default function SidebarComponent() {
  const [open, setOpen] = useState(true);
  const { logout } = useAuth();

  const logoutAPI = usePostData();

  const navigation = useNavigate();

  const handleLogOut = () => {
    logoutAPI.mutate(
      {
        url: "users/signout",
      },
      {
        onSuccess: () => {
          axiosInstance.defaults.headers.common["Authorization"] = null;
          logout();
          navigation(routes.LOGIN);
        },
      }
    );
  };
  return (
    <div
      className={`${
        open ? "w-72" : "w-20 "
      } bg-purple-800 h-screen p-5 pt-8 relative duration-300`}
    >
      <img
        src="./src/assets/control.png"
        className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple
         border-2 rounded-full  ${!open && "rotate-180"}`}
        onClick={() => setOpen(!open)}
      />
      <div className="flex gap-x-4 items-center">
        <img
          src="./src/assets/logo.png"
          className={`cursor-pointer duration-500 ${open && "rotate-[360deg]"}`}
        />
        <h1
          className={`text-white origin-left font-medium text-xl duration-200 ${
            !open && "scale-0"
          }`}
        >
          Mummly
        </h1>
      </div>
      <ul className="pt-6">
        {Menus.map(({ title, Icon, link }, index) => (
          <NavLink
            to={link}
            key={index}
            className={({ isActive }) =>
              `flex rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-4
               ${isActive && "bg-light-white"}`
            }
          >
            <Icon style={{ height: "1.25rem", width: "1.25rem" }} />
            <span className={`${!open && "hidden"} origin-left duration-200`}>
              {title}
            </span>
          </NavLink>
        ))}
      </ul>
      <div
        className="flex rounded-md p-2 cursor-pointer text-gray-300 text-sm items-center gap-x-4 
         mt-4 hover:bg-light-white"
        onClick={handleLogOut}
      >
        <RiLogoutCircleLine style={{ height: "1.25rem", width: "1.25rem" }} />
        <span className={`${!open && "hidden"} origin-left duration-200`}>
          Logout
        </span>
      </div>
    </div>
  );
}
