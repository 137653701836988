import { createContext, useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../constants/routes.constants";

export const AuthContext = createContext({
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
});

// eslint-disable-next-line react/prop-types
const AuthProvider = ({ children }) => {
  const location = useLocation();
  const [token, setToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      setIsLoggedIn(false);
      if (location.pathname !== routes.LOGIN)
        localStorage.setItem("previousPath", location.pathname);
      navigate(routes.LOGIN);
    }
  }, [location.pathname, navigate, token]);

  const login = (token) => {
    setIsLoggedIn(true);
    setToken(token);
    const previousPath = localStorage.getItem("previousPath");
    navigate(
      previousPath && previousPath !== routes.LOGIN ? previousPath : routes.HOME
    );
  };

  const logout = () => {
    setToken(null);
    setIsLoggedIn(false);
    navigate(routes.LOGIN);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { useAuth, AuthProvider };
