import { Outlet } from "react-router-dom";
import Sidebar from "./components/SideBar/SideBar";

const AppLayout = () => {
  return (
    <div className="flex">
      <Sidebar />
      <div className="h-screen flex-1 p-7">
        <Outlet />
      </div>
    </div>
  );
};

export default AppLayout;
